import $ from 'jquery';
import weatherpage from "./weatherpage";
import videoButton from "./components/videoButton";
import linkSlider from "./components/linkSlider";
import generalSlider from "./components/generalSlider";
import imageTextPopup from "./components/imageTextPopup";
import menu from "./components/menu";

$(document).ready(function () {
	weatherpage($);
	videoButton($);
	linkSlider($);
	generalSlider($);
	imageTextPopup($);
	menu($);

    $(window).on('load', function () {
        var headerHeight = $('#js-anchor-top').outerHeight();
        $('.overlay-blue').css('height', headerHeight);
        $(window).resize(function () {
            var headerHeight = $('#js-anchor-top').outerHeight();
            $('.overlay-blue').css('height', headerHeight);
        });
    });

    //TOGGLE SEARCH
    $('.js-toggle-search').click(function () {
        $('.search-overlay').toggleClass('toggled');
    });
    //MENU STUFF
    $('a.active').closest('li').addClass('active');
    if ($('li.active').find('nav').length > 0) {
        $('header nav.main').addClass('has-sub');
    }
    // //SCROLL FIX MENU
    // if ($('body').hasClass('ie8') || $('header').hasClass('search-head')) {
    //     //do nothing
    // } else {
    //     var $document = $(document),
    //         $element = $('nav'),
    //         className = 'solid';

    //     $document.scroll(function () {
    //         if ($document.scrollTop() >= 50) {

    //             $element.addClass(className);
    //         } else {
    //             $element.removeClass(className);
    //         }
    //     });

    //     var topofDiv = $("header").offset().top;
    //     var height = $("header").outerHeight();
    //     $(window).scroll(function () {

    //         if ($(window).scrollTop() > (topofDiv + height)) {
    //             if ($('#bgvid').length > 0) {
    //                 $('#bgvid').get(0).pause();
    //             }
    //         } else if ($(window).scrollTop() == topofDiv) {
    //             if ($('#bgvid').length > 0) {
    //                 $('#bgvid').get(0).play();
    //             }
    //         }
    //     });
    // }

    //SCROLL UP OR DOWN?
    $('.js-scroll-down').click(function () {
        if ($('body').width() < 1030) {
            $('html,body').animate({
                scrollTop: $("#js-anchor").offset().top - 60
            }, 1000);
        } else {
            $('html,body').animate({
                scrollTop: $("#js-anchor").offset().top - 95
            }, 1000);
        }

    });

    if ($('section.search').length > 0 && $('.search-result').length > 2) {

        $('footer').css('position', 'absolute', 'bottom', '0');

    }

    $('.js-scroll-up').click(function () {
        $('html,body').animate({
            scrollTop: $("#js-anchor-top").offset().top
        }, 1000);
    });

    //PINS-MAPS-NUMBERS-TRAPS-andtigersohmy
    $('.pin').click(function (e) {
        $('.bubble').removeClass('toggled');

        var bubble = $(this).children('.bubble');
        if (bubble.hasClass('toggled')) {
            bubble.removeClass('toggled');
        }
        else {
            bubble.addClass('toggled');
        }
        e.stopPropagation();
    });

    $('body').click(function () {
        if ($('.bubble').hasClass('toggled')) {
            $('.bubble').removeClass('toggled');
        }
    });



    //open box of content
    $('.bubble').click(function (e) {
        var clicked_pin = $(this).parent('.pin').attr('id').substring(4, 6);
        var content = $('#content-' + clicked_pin);
        if ($(this).hasClass('toggled')) {
            e.stopPropagation();
            $(this).removeClass('toggled');
            content.fadeIn();
        }
        e.stopPropagation();
    });

    //navigate between boxes of contentststs
    //previous
    $('.js-prev-pin').click(function () {
        var content_index = $(this).parents('.pg-content').attr('id').substring(8, 10);
        var current_content = $('#content-' + content_index);
        var prev_content = $('#content-' + (parseInt(content_index) - 1));
        current_content.hide();
        prev_content.show();
    });
    //next
    $('.js-next-pin').click(function () {
        var content_index = $(this).parents('.pg-content').attr('id').substring(8, 10);
        var current_content = $('#content-' + content_index);
        var next_content = $('#content-' + (parseInt(content_index) + 1));
        current_content.hide();
        next_content.show();
    });

    //IE8 stuff
    if ($('body').hasClass('ie8') || $('header').hasClass('search-head')) {
        $('header nav').addClass('fixed');
        $('header nav').addClass('active');
        $('.search-overlay').addClass('active');
    }
    //GALLERY FUNCTION
    $('.js-set-image[data-id="5"]').addClass('active');
    $('.js-set-image').click(function () {
        var imageSource = $(this).attr('src');
        var imageAlt = $(this).attr('alt');
        $(this).parent().parent().find('.js-get-image').attr('src', imageSource);
        $(this).parent().parent().find('.js-get-image').attr('alt', imageAlt);
        $('.js-set-image').removeClass('active');
        $(this).addClass('active');
    });

        $('.js-burger-wrap').on('click', function() {
            toggleMenu();
        });

});

//functions
function close_content() {
    $('.pg-content').fadeOut(200);
    $('.bubble').removeClass('toggled');
    $('.js-set-image').removeClass('active');
    $('.js-set-image[data-id="5"]').addClass('active');
}
function show_plot_area() {
    $('#canvas-plot-area').addClass('toggled');
    $('.hide-graph').fadeIn(700);
}
function hide_plot_area() {
    $('#canvas-plot-area').removeClass('toggled');
    $('.hide-graph').fadeOut(300);
}
function toggleMenu() {
    $('header nav ul').toggleClass('toggled');
    $('.burger-wrap').toggleClass('toggled');
    $('#nav-toggle').toggleClass('active');
}
