import Flickity from 'flickity';

export default function linkSlider($) {
	const linkSliders = $('[data-js="link-slider"]');

	if ( linkSliders.length > 0 ) {
		for (let i = 0; i < linkSliders.length; i++) {
			let flkty = new Flickity( linkSliders[i], {
                cellSelector: '.link-slider__slide',
                wrapAround: false,
                prevNextButtons: true,
                pageDots: false,
                cellAlign: 'center',
                contain: true
            });

            window.addEventListener( 'load', function() {
				flkty.resize()
			});
        }
	}
}