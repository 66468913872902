export default function menu($) {
	const mobileMenuToggle = $('[data-js="menu-toggle"]');
	const mobileMenu = $('[data-js="mobile-menu"]');
	const menuItemSub = $('.site-navigation__mobile .menu-item-has-children');
	const mobileClose = $('[data-js="mobile-menu-close"]');
	const mobileSubMenuClose = $('[data-js="mobile-menu-back"]');
	const allSubMenus =  $('.site-navigation__mobile .sub-menu');

	mobileMenuToggle.on('click', function() {
		mobileMenu.toggleClass('open');
	});

	menuItemSub.each(function(i, item) {
		const subButton = '<span class="sub-button"></span>';
		$(item).append(subButton);
	});

	const subMenuButton = $('.sub-button');
	subMenuButton.each(function(i, button) {
		$(button).on('click', function() {
			mobileSubMenuClose.addClass('show');
			$(this).prev().toggleClass('open');
		});
	});

	mobileSubMenuClose.on('click', function() {
		$(this).removeClass('show');
		allSubMenus.each(function(i, menu) {
			$(menu).removeClass('open');
		});
	});

	mobileClose.on('click', function() {
		mobileMenu.removeClass('open');

		allSubMenus.each(function(i, menu) {
			$(menu).removeClass('open');
		});
	});
}