export default function videoButton ($) {
	const videoButtons = $('[data-js="play-button"]');

	if ( videoButtons.length > 0 ) {
		videoButtons.on('click', function() {
			$(this).next().attr('controls', true);
			$(this).next()[0].play();
			$(this).hide();
		});
	}
}