import Flickity from 'flickity';

export default function generalSlider($) {
	const sliders = $('[data-js="general-slider"]');

	if ( sliders.length > 0 ) {
		for (let i = 0; i < sliders.length; i++) {
			let flkty = new Flickity( sliders[i], {
                cellSelector: '.general-slider__slide',
                wrapAround: false,
                prevNextButtons: true,
                pageDots: false,
                cellAlign: 'center',
                contain: true
            });

            window.addEventListener( 'load', function() {
				flkty.resize()
			});
        }
	}
}