﻿export default function ($) {

	var Chart = window.Chart;

	function wpAjaxRequest(data, callback) {
		return $.ajax({
			type: "POST",
			url: window.ajaxUrl,
			dataType:'JSON',
			data: data,
			success: callback,
		});
	}

	function initCurrentConditions() {
		wpAjaxRequest({ action: 'weather_current_conditions' }, function (response) {
			var data = response.data;
			if (data) {
				document.getElementById('js-current-weather-time').innerHTML = data.time;
				document.getElementById('js-current-weather-temperature').innerHTML = data.temperature + '°C';
				document.getElementById('js-current-weather-humidity').innerHTML = data.humidity + '%';
				document.getElementById('js-current-weather-barometer').innerHTML = data.barometer;
			}
		});
	}

	//Init the 24h back diagram.
	function init24HourBackDiagram() {
		var labels = [], temperatures = [], zeroTemperatures = [];
		var dateTimeNow = new Date();
		var minus24Hours = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
		var canvasElementID = 'canvas';
		$('#'+canvasElementID).addClass('loading-spinner');

		var requestData = {
			action: 'weather_temperature_history',
			from: formatDateForQuery(minus24Hours),
			to: formatDateForQuery(dateTimeNow)
		};
		wpAjaxRequest(requestData, function (response) {
			var data = response.data;
			$.each(data, function (i, item) {
				if (i % 48 == 0) {
					labels.push(formatTimeStamp(item.datetime));
				} else {
					labels.push("");
				}
				temperatures.push(parseFloat(item.temperature));
				zeroTemperatures = temperatures.slice();
				fillArray(zeroTemperatures, 0);
			});

			var minus24HoursChartData = {
				labels: labels,
				datasets: [
					{
						label: "24 hours back",
						strokeColor: "rgba(151,187,205,1)",
						pointColor: "rgba(151,187,205,1)",
						pointStrokeColor: "#fff",
						pointHighlightFill: "#fff",
						pointHighlightStroke: "rgba(151,187,205,1)",
						data: temperatures
					}, {
						label: "0-Axis",
						strokeColor: "rgba(151,187,205,0.5)",
						data: zeroTemperatures
					}]
			};

			var stepWidth = getScaleWidthSteps(7, temperatures);
			var min = parseFloat(roundHalf(Math.min.apply(Math, temperatures)));
			var ctx = document.getElementById(canvasElementID).getContext("2d");
			var twentyFourHourChart = new Chart(ctx).Line(minus24HoursChartData, {
				responsive: true,
				animationSteps: 10,
				scaleShowGridLines: false,
				showTooltips: false,
				datasetStrokeWidth: 2,
				datasetFill: false,
				//scaleOverride: true,
				//scaleSteps: 7,
				//scaleStepWidth: stepWidth,
				//scaleStartValue: min-1,
				scaleIntegersOnly: true,
				pointDot: false,
				datasetStroke: false,
				bezierCurve: false
			});
			$('#'+canvasElementID).removeClass('loading-spinner');
		});
	}

		//Init the one week back diagram.
	function initOneWeekBackDiagram() {
		var labels = [], temperatures = [], zeroTemperatures = [];
		var dateTimeNow = new Date();
		var minusOneWeek = new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000));
		var canvasElementID = 'canvasWeek';
		$('#'+canvasElementID).addClass('loading-spinner');

		var requestData = {
			action: 'weather_temperature_average',
			from: formatDateForQuery(minusOneWeek),
			to: formatDateForQuery(dateTimeNow),
			resolution: 'hour',
		};
		wpAjaxRequest(requestData, function (response) {
			var data = response.data;
			$.each(data, function (i, item) {
				if (i % 24 == 0) {
					labels.push(formatDateStamp(item.datetime));
				} else {
					labels.push("");
				}
				temperatures.push(parseFloat(item.avgtemp));
				zeroTemperatures = temperatures.slice();
				fillArray(zeroTemperatures, 0);
			});

			var minusOneWeekChartData = {
				labels: labels,
				datasets: [
					{
						label: "One week back",
						strokeColor: "rgba(151,187,205,1)",
						pointColor: "rgba(151,187,205,1)",
						pointStrokeColor: "#fff",
						pointHighlightFill: "#fff",
						pointHighlightStroke: "rgba(151,187,205,1)",
						data: temperatures
					}, {
						label: "0-Axis",
						strokeColor: "rgba(151,187,205,0.5)",
						data: zeroTemperatures
					}]

			};

			var stepWidth = getScaleWidthSteps(7, temperatures);
			var min = parseFloat(roundHalf(Math.min.apply(Math, temperatures)));
			var ctx = document.getElementById(canvasElementID).getContext("2d");
			var weekChart = new Chart(ctx).Line(minusOneWeekChartData, {
				responsive: true,
				animationSteps: 10,
				scaleShowGridLines: false,
				showTooltips: false,
				datasetStrokeWidth: 2,
				datasetFill: false,
				//scaleOverride: true,
				//scaleSteps: 7,
				//scaleStepWidth: stepWidth,
				//scaleStartValue: min - 1,
				scaleIntegersOnly: true,
				pointDot: false,
				datasetStroke: false,
				bezierCurve: false
			});
			$('#'+canvasElementID).removeClass('loading-spinner');
		});
	}

	//Init the one month back diagram.
	function initOneMonthBackDiagram() {
		var labels = [], temperatures = [], zeroTemperatures = [];
		var dateTimeNow = new Date();
		var minusOneMonth = new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000));
		var canvasElementID = 'canvasMonth';
		$('#'+canvasElementID).addClass('loading-spinner');

		var requestData = {
			action: 'weather_temperature_average',
			from: formatDateForQuery(minusOneMonth),
			to: formatDateForQuery(dateTimeNow),
			resolution: 'hour',
		};
		wpAjaxRequest(requestData, function (response) {
			var data = response.data;
			$.each(data, function (i, item) {
				if (i % 72 == 0) {
					labels.push(formatDateStamp(item.datetime));
				} else {
					labels.push("");
				}
				temperatures.push(parseFloat(item.avgtemp));
				zeroTemperatures = temperatures.slice();
				fillArray(zeroTemperatures, 0);
			});

			var minusOneMonthChartData = {
				labels: labels,
				datasets: [
					{
						label: "One month back",
						strokeColor: "rgba(151,187,205,1)",
						pointColor: "rgba(151,187,205,1)",
						pointStrokeColor: "#fff",
						pointHighlightFill: "#fff",
						pointHighlightStroke: "rgba(151,187,205,1)",
						data: temperatures
					}, {
						label: "0-Axis",
						strokeColor: "rgba(151,187,205,0.5)",
						data: zeroTemperatures
					}]

			};

			var stepWidth = getScaleWidthSteps(7, temperatures);
			var min = parseFloat(roundHalf(Math.min.apply(Math, temperatures)));
			var ctx = document.getElementById(canvasElementID).getContext("2d");
			var monthChart = new Chart(ctx).Line(minusOneMonthChartData, {
				responsive: true,
				animationSteps: 10,
				scaleShowGridLines: false,
				showTooltips: false,
				datasetStrokeWidth: 2,
				datasetFill: false,
				//scaleOverride: true,
				//scaleSteps: 7,
				//scaleStepWidth: stepWidth,
				//scaleStartValue: min - 1,
				scaleIntegersOnly: true,
				pointDot: false,
				datasetStroke: false,
				bezierCurve: false
			});
			$('#'+canvasElementID).removeClass('loading-spinner');
		});
	}

	//Init the plot diagram.
	function initPlotDiagram() {
		$('#plot-button').click(function () {
			$('#canvas-plot-area').addClass('toggled');
			$('.hide-graph').fadeIn(700);
			var labels = [], temperatures = [], zeroTemperatures = [];
			var canvasElementID = 'canvas-plot';
			$('#'+canvasElementID).addClass('loading-spinner');
			var requestData = {
				action: 'weather_temperature_average',
				from: formatPlotDateForQuery($("#from-year").val(), $("#from-month").val(), $("#from-date").val()),
				to: formatPlotDateForQuery($("#to-year").val(), $("#to-month").val(), $("#to-date").val()),
				resolution: getResolution(),
			};
			wpAjaxRequest(requestData, function (response) {
				var data = response.data;
				$.each(data, function (i, item) {
					if (i % getLabelModulus(data.length, 5) == 0) {
						labels.push(formatYearDateStamp(item.datetime));
					} else {
						labels.push("");
					}
					temperatures.push(parseFloat(item.avgtemp));
					zeroTemperatures = temperatures.slice();
					fillArray(zeroTemperatures, 0);
				});

				var plotChartData = {
					labels: labels,
					datasets: [
					{
						label: "One month back",
						strokeColor: "rgba(151,187,205,1)",
						pointColor: "rgba(151,187,205,1)",
						pointStrokeColor: "#fff",
						pointHighlightFill: "#fff",
						pointHighlightStroke: "rgba(151,187,205,1)",
						data: temperatures
					}, {
						label: "0-Axis",
						strokeColor: "rgba(151,187,205,0.5)",
						data: zeroTemperatures
					}]

				};

				var stepWidth = getScaleWidthSteps(7, temperatures);
				var min = parseFloat(roundHalf(Math.min.apply(Math, temperatures)));
				var ctx = document.getElementById(canvasElementID).getContext("2d");
				var plotChart = new Chart(ctx).Line(plotChartData, {
					responsive: true,
					animationSteps: 100,
					scaleShowGridLines: false,
					showTooltips: false,
					datasetStrokeWidth: 2,
					datasetFill: false,
					//scaleOverride: true,
					//scaleSteps: 7,
					//scaleStepWidth: stepWidth,
					//scaleStartValue: min - 1,
					scaleIntegersOnly: true,
					pointDot: false,
					datasetStroke: false,
					bezierCurve: false,
					scaleFontColor: "#fff"
				});

				$('#canvas-plot-area').show('slow');
				$('#'+canvasElementID).removeClass('loading-spinner');
			});

		});

		$('#hide_plot_area').on('click', function() {
			$('#canvas-plot-area').removeClass('toggled');
			$('.hide-graph').fadeOut(300);
		});
	}

	var monthlyAverages = (function () {
		function monthlyAverages(lbl, sYear, eYear) {
			this.label = lbl;
			this.startYear = sYear;
			this.endYear = eYear;
			this.temperatures = [];
		}
		return monthlyAverages;
	}());

	function initMonthlyAverageDiagram() {
		var currentDate = new Date();

		//If we have past May of current year, then set start year as (current year -1)
		var firstStartYear = currentDate.getMonth() > 4 ? currentDate.getFullYear() -1 : currentDate.getFullYear() - 2;

		var annualAverages = [];
		var requests = [];

		for (var i = 0; i < 5; i++) {
			var y = firstStartYear - i;
			var y1 = y + 1; //y1 will be current year on first iteration.
			annualAverages.push({
				label: y + "-" + y1,
				startYear: y,
				endYear: y1,
				temperatures: [],
			});
			//annualAverages.push(new monthlyAverages(y + "-" + y1, y, y1));
		}
		var zeroTemperatures = [0,0,0,0,0,0,0,0];
		var canvasElementID = 'canvasMonthlyAverage';
		$('#'+canvasElementID).addClass('loading-spinner');

		$.when(
			wpAjaxRequest({ action: 'weather_temperature_average', from: annualAverages[4].startYear+'-10-01T00:00&to=', to: annualAverages[4].endYear+'-05-31T00:00', resolution: 'month' },
			function (response) {
				$.each(response.data, function (i, item) {
					annualAverages[4].temperatures.push(parseFloat(item.avgtemp));
				});
			}),
			wpAjaxRequest({ action: 'weather_temperature_average', from: annualAverages[3].startYear+'-10-01T00:00&to=', to: annualAverages[3].endYear+'-05-31T00:00', resolution: 'month' },
			function (response) {
				$.each(response.data, function (i, item) {
					annualAverages[3].temperatures.push(parseFloat(item.avgtemp));
				});
			}),
			wpAjaxRequest({ action: 'weather_temperature_average', from: annualAverages[2].startYear+'-10-01T00:00&to=', to: annualAverages[2].endYear+'-05-31T00:00', resolution: 'month' },
			function (response) {
				$.each(response.data, function (i, item) {
					annualAverages[2].temperatures.push(parseFloat(item.avgtemp));
				});
			}),
			wpAjaxRequest({ action: 'weather_temperature_average', from: annualAverages[1].startYear+'-10-01T00:00&to=', to: annualAverages[1].endYear+'-05-31T00:00', resolution: 'month' },
			function (response) {
				$.each(response.data, function (i, item) {
					annualAverages[1].temperatures.push(parseFloat(item.avgtemp));
				});
			}),
			wpAjaxRequest({ action: 'weather_temperature_average', from: annualAverages[0].startYear+'-10-01T00:00&to=', to: annualAverages[0].endYear+'-05-31T00:00', resolution: 'month' },
			function (response) {
				$.each(response.data, function (i, item) {
					annualAverages[0].temperatures.push(parseFloat(item.avgtemp));
				});
			})
		).done(function (data, data2, data3, data4, data5) {

				var monthlyAverageChartData = {
					labels: ["Oct", "Nov", "Dec", "Jan", "Feb", "Mar", "Apr", "May"],

					datasets: [
					{
						label: annualAverages[4].label,
						strokeColor: "rgba(151,187,205,1)",
						pointColor: "rgba(151,187,205,1)",
						pointStrokeColor: "#fff",
						pointHighlightFill: "#fff",
						pointHighlightStroke: "rgba(151,187,205,1)",
						data: annualAverages[4].temperatures
					}, {
						label: annualAverages[3].label,
						strokeColor: "rgba(151,187,105,1)",
						pointColor: "rgba(151,187,105,1)",
						pointStrokeColor: "#fff",
						pointHighlightFill: "#fff",
						pointHighlightStroke: "rgba(151,187,105,1)",
						data: annualAverages[3].temperatures
					}, {
						label: annualAverages[2].label,
						strokeColor: "rgba(251,187,105,1)",
						pointColor: "rgba(251,187,105,1)",
						pointStrokeColor: "#fff",
						pointHighlightFill: "#fff",
						pointHighlightStroke: "rgba(251,187,105,1)",
						data: annualAverages[2].temperatures
					}, {
						label: annualAverages[1].label,
						strokeColor: "rgba(251,107,205,1)",
						pointColor: "rgba(251,187,205,1)",
						pointStrokeColor: "#fff",
						pointHighlightFill: "#fff",
						pointHighlightStroke: "rgba(51,187,205,1)",
						data: annualAverages[1].temperatures
					}, {
						label: annualAverages[0].label,
						strokeColor: "rgba(151,287,205,1)",
						pointColor: "rgba(151,287,205,1)",
						pointStrokeColor: "#fff",
						pointHighlightFill: "#fff",
						pointHighlightStroke: "rgba(151,287,205,1)",
						data: annualAverages[0].temperatures

					}]
				};
				var ctx = document.getElementById(canvasElementID).getContext("2d");
				var monthlyAverageChart = new Chart(ctx).Line(monthlyAverageChartData, {

					responsive: true,
					animationSteps: 100,
					scaleShowGridLines: false,
					showTooltips: true,
					datasetStrokeWidth: 1,
					datasetFill: false,
					scaleShowLabels: true,
					scaleIntegersOnly: false,
					pointDot: true,
					datasetStroke: true,
					multiTooltipTemplate: "<%= datasetLabel %>: <%= value %>",
						bezierCurve: true,
						animationEasing: "easeOutQuart",
						StrokeColor: "#f9f9f9",
						tooltipTemplate: "<%if (datasetLabel){%><%= datasetLabel %>: <%}%><%= value %>%",
						legendTemplate: "<ul class=\"<%=name.toLowerCase()%>-legend\"><% for (var i=0; i<datasets.length; i++){%><li><span style=\"background-color:<%=datasets[i].pointColor%>\"></span><%if(datasets[i].label){%><%=datasets[i].label%><%}%></li><%}%></ul>"
				});
				document.getElementById('legendDiv').innerHTML = monthlyAverageChart.generateLegend();
				$('#'+canvasElementID).removeClass('loading-spinner');
			});
	}

    //Formats a date object to be used in querystring such as [2014-01-01T12:00]
    function formatDateForQuery(dateTime) {
        var d = new Date(dateTime);
        var s = d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + d.getDate() + 'T' + ('0' + (d.getHours())).slice(-2) + ':' + ('0' + (d.getMinutes())).slice(-2);
        return s;
    }

    //Formats a date object to be used in querystring such as [2014-01-01T12:00]
    function formatPlotDateForQuery(year, month, date) {
        var d = new Date();
        var dateInt = parseInt(date);
        var s = year + '-' + ('0' + month).slice(-2) + '-' + date + 'T' + ('0' + (d.getHours())).slice(-2) + ':' + ('0' + (d.getMinutes())).slice(-2);
        return s;
    }

    //Format x-axis labels as [hh:mm]
    function formatTimeStamp(dateTime) {
    var ts = new Date(dateTime);
    var tzo = (ts.getTimezoneOffset() / 60);
    var s = ('0' + (Math.abs(ts.getHours() + tzo))).slice(-2) + ':' + ('0' + (ts.getMinutes())).slice(-2);
    return s;
}

    //Format x-axis labels as [1/12]
    function formatDateStamp(dateTime) {
    var ts = new Date(dateTime);
    var s = ts.getDate() + '/' + ('0' + (ts.getMonth() + 1)).slice(-2);
    return s;
    }

    //Format x-axis labels as [1/12 - 2014]
    function formatYearDateStamp(dateTime) {
        var ts = new Date(dateTime);
        var s = ts.getDate() + '/' + ('0' + (ts.getMonth() + 1)).slice(-2) + ' - ' + ts.getFullYear();
        return s;
    }

    //Returns the modulus kofficient based on number of desired Labels and number of items in series.
    function getLabelModulus(numberOfItems, maxNumberOfLabels) {
        return parseInt(numberOfItems / maxNumberOfLabels);
    }

    //Gets the number of das between two dates.
    function dayDiff(first, second) {
        return (second - first) / (1000 * 60 * 60 * 24);
    }

    //Return hour resolution if number days in interval is < 75 days.
    function getResolution() {
        if (dayDiff(getDateFromControls('#from-date', '#from-month', '#from-year'), getDateFromControls('#to-date', '#to-month', '#to-year')) < 75)
            return 'hour';
        else
            return 'day';
    }

    //Returns a date object from select controls for year, month and day.
    function getDateFromControls(dayf, monthf, yearf) {
        return new Date($(yearf).val().toString(), ('0' + $(monthf).val().toString()).slice(-2) - 1, $(dayf).val().toString());
    }

    var monthtext = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

    //Populates dropdown for year, month and day.
    function populateDropdown(dayf, monthf, yearf) {

        var today = new Date();
        var dayfield = document.getElementById(dayf);
        var monthfield = document.getElementById(monthf);
        var yearfield = document.getElementById(yearf);

        for (var i = 0; i < 31; i++)
            dayfield.options[i] = new Option(i + 1, i + 1);

        dayfield.options[today.getDate() - 1].selected = true;

        for (var m = 0; m < 12; m++)
            monthfield.options[m] = new Option(monthtext[m], m + 1);

        monthfield.options[today.getMonth()].selected = true;

        var thisyear = today.getFullYear();

        for (var y = 0; y < 5; y++) {
            yearfield.options[y] = new Option(thisyear, thisyear);
            thisyear -= 1;
        }

        yearfield.options[0] = new Option(today.getFullYear(), today.getFullYear(), true, true); //select today's year
    }
    var roundHalf = function (n) {
        return (Math.round(n * 2) / 2).toFixed(1);
    };
    var getScaleWidthSteps = function(n,array) {
        var max = parseFloat(roundHalf(Math.max.apply(Math, array)));
        var min = parseFloat(roundHalf(Math.min.apply(Math, array)));
        var stepWidth = roundHalf(((max + 2) - (min - 1)) / n);
        return stepWidth;
    }
    var fillArray = function(arr,value) {
        for (var i = 0; i < arr.length; i++) {
            arr[i] = value;
        }
    }

    if (document.getElementsByClassName('page-template-tmp-weather-php').length)
	{
		initCurrentConditions();

		//Inits the different diagrams.
		init24HourBackDiagram();
		initOneWeekBackDiagram();
		initOneMonthBackDiagram();
		initMonthlyAverageDiagram();
		initPlotDiagram();

		//Inits the dropdowns for plot diagram.
		populateDropdown('from-date', 'from-month', 'from-year');
		populateDropdown('to-date', 'to-month', 'to-year');
	}
}