export default function imageTextPopup ($) {
	const popupButtons = $('[data-js="image-text-popup-open"]');
	const popupCloseButtons = $('[data-js="image-text-popup-close"]');

	if ( popupButtons.length > 0 ) {
		popupButtons.on('click', function(e) {
			const popup = $(this).siblings('[data-js="image-text-popup"]')[0];
			$(popup).toggleClass('open');
		});

		popupCloseButtons.on('click', function(e) {
			const popup = $('[data-js="image-text-popup"]')[0];
			$(popup).toggleClass('open');
		});
	}
}